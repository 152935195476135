/* テーブル
========================================================= */

.is-selected-item {
  z-index: 1;
  margin-bottom: 10px;
  height: 35px;
  background-size: cover;
  border-radius: 9px;
  border: 2px solid #338dc9;
  background-position: center;
  background-color: #338dc9;
  background-image: repeating-linear-gradient(
    -45deg,
    transparent 0,
    transparent 3px,
    #fff 3px,
    #fff 6px
  );
}

.c-calendar__table-wrap {
  overflow: scroll;
  overflow-x: hidden;
  background-color: #ffffff;
  position: relative;
  margin-top: 11px;
  &.u-guests__line {
    margin-top: 0;
    &::before {
      position: absolute;
      content: "";
      width: 80%;
      height: 250%;
      top: 0;
      bottom: 0;
      left: 100px;
      background-color: rgba(102, 203, 196, 0.3);
      z-index: 2;
    }
  }
}

.c-calendar__table-sub {
  // table-layout: fixed;
  font-size: 10px;
  color: #fff;
  background: #01a69b;
  border-collapse: collapse;
  border-spacing: 0;
  position: sticky;
  height: 50px;
  z-index: 1;
  border-spacing: 0px;
  // margin: auto;

  tr {
    height: 15px;
    user-select: none;

    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      background: #fff;
      bottom: 12.5px;
      right: 0px;
      margin: auto;
    }

    th {
      width: 90px;
      max-width: 90px;
      min-width: 90px;
    }

    td {
      width: 20px;
      max-width: 20px;
      min-width: 20px;
      position: relative;
      &:first-child {
        .c-calendar__table-sub__time {
          left: -3vw;
        }
      }

      .c-calendar__table-sub__time {
        user-select: none;
        position: absolute;
        left: -4vw;
        top: 2px;
        font-size: 10px;
      }

      &::after {
        position: absolute;
        content: "";
        width: 2px;
        height: 20px;
        background: #fff;
        bottom: 0;
        left: -2px;
        margin: auto;
      }

      &:nth-of-type(even)::after {
        height: 0;
      }

      &:last-child {
        padding-right: 20px;
      }
    }
  }
}

.c-calendar__table-main__wrap {
  margin-left: 5px;
  // max-height: 70vh;
  max-height: calc(100vh - 35px - 11px - 24px - 8px - 50px);
  // padding-bottom: 5rem;
  overflow: scroll;
  @include media(side) {
    // max-height: 50vh;
  }

  .spaces {
    //will-change: transform;
  }

  .c-calendar__table {
    font-size: 13px;
    color: #666;
    border-collapse: collapse;
    border-spacing: 0;
    position: relative;
    .c-calendar__table-body {
      border-bottom: 1px solid #e6e6e6;
    }
    .c-calendar__table-body__wrap {
      display: flex;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: calc(100% - 10px);
        height: 1px;
        background-color: #e6e6e6;
        top: 10px;
        z-index: 1;
      }
    }
    .c-calendar__table-th__wrap {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 2px;
        height: 10px;
        background-color: #e6e6e6;
        right: 0;
        top: 0;
        z-index: 1;
      }
    }
  }

  .c-calendar__table-tr {
    display: inline-flex;
    position: relative;
    // &::after {
    //     position: absolute;
    //     content: "";
    //     width: 100%;
    //     height: 1px;
    //     background-color: #E6E6E6;
    //     top: 0;
    //     left: 0;
    //     z-index: 1;
    // }

    .c-calendar__table-th {
      position: relative;
    }
  }

  .c-calendar__table-th {
    width: 120px;
    max-width: 120px;
    min-width: 120px;
    padding: 0px 7px 4px 17px;
    min-height: 70px;
    border-right: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.is-selected {
      &::before {
        position: absolute;
        content: "";
        width: calc(100vw - 9px);
        height: 2px;
        right: -66vw;
        bottom: 0;
        top: 2px;
        background: #54ace6;
        z-index: 1;
        @include media(m) {
          right: -69vw;
        }
        @include media(s) {
          right: -63vw;
        }
        @include media(xs) {
          right: -60vw;
        }
      }
      &::after {
        position: absolute;
        content: "";
        width: calc(100vw - 9px);
        height: 2px;
        right: -66vw;
        bottom: 2px;
        background: #54ace6;
        z-index: 1;
        @include media(m) {
          right: -69vw;
        }
        @include media(s) {
          right: -63vw;
        }
        @include media(xs) {
          right: -60vw;
        }
      }
      .c-calendar__table-conference {
        user-select: none;
        &::before {
          position: absolute;
          content: "";
          width: 2.5px;
          height: calc(100% - 6px);
          right: -66vw;
          bottom: 0;
          top: 2px;
          background: #54ace6;
          z-index: 1;
          @include media(m) {
            right: -69vw;
          }
          @include media(s) {
            right: -63vw;
          }
          @include media(xs) {
            right: -60vw;
          }
        }
        &::after {
          position: absolute;
          content: "";
          width: 7px;
          height: calc(100% - 4px);
          left: 0;
          bottom: 0;
          top: 2px;
          background: #54ace6;
          z-index: 1;
        }
      }
    }
    &:first-of-type {
      margin-top: 10px;
    }
  }
  .c-calendar__table-main__box {
    &:first-of-type {
      margin-top: 10px;
      .c-calendar__table-td {
        &:last-child,
        &:nth-child(odd) {
          &::before {
            position: absolute;
            content: "";
            width: 2px;
            height: 10px;
            top: -10px;
            left: -2px;
            background-color: #e6e6e6;
            z-index: 10;
          }
        }
      }
    }
  }

  .c-calendar__table-conference {
    user-select: none;
    letter-spacing: 0.39px;
    color: #666;
    font-size: 13px;
  }

  .c-calendar__table-people {
    user-select: none;
    font-size: 10px;
    letter-spacing: 0.33px;
    color: #666;
    width: 45px;
    margin-top: auto;
    position: relative;
    padding-left: 1.1rem;

    &::after {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 14px;
      height: 9px;
      background-image: url($img_path + "common/people-icon.png");
      background-size: contain;
    }
  }

  .c-calendar__table-td__wrap {
    display: inline-flex;
    position: relative;
    height: 70px;

    user-select: none;
    * {
      user-select: none;
    }
  }

  .c-calendar__table-td {
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    max-width: 20px;
    min-width: 20px;
    padding: 15px 0 3px;
    border-right: 2px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    position: relative;

    // &:last-of-type,
    &:nth-child(odd) {
      border-right: none;

      // &::before {
      //     position: absolute;
      //     content: "";
      //     width: 2px;
      //     height: 10px;
      //     top: -10px;
      //     left: -2px;
      //     background-color: #E6E6E6;
      // }
    }

    .is-already {
      background: #666;
      width: 100%;
      height: 35px;

      &.u-user01 {
        background-color: #a9ddf3;
      }

      &.u-user02 {
        background-color: #f4777c;
      }

      &.u-user03 {
        background-color: #88d392;
      }

      &.u-user04 {
        background-color: #fdf57a;
      }
    }
  }
}
