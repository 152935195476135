/* テキスト
========================================================= */

.c-nav_ttl {
  margin-bottom: 10px;
  font-size: 1.6em;
  text-align: center;
  max-width: 138px;
  margin-top: 18px;
  margin-left: 22px;
  line-height: 1;
}

.c-modal-ttl {
  font-size: 13px;
  font-weight: bold;
  padding: 13px 0;
  padding-left: 66px;
  letter-spacing: 3px;
  border-radius: 32px;
  color: #666666;
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.2);
  // margin: 0 auto 22px;
  margin: 0 auto;
  position: relative;
  width: 95%;
  max-width: 337px;
  cursor: pointer;

  &::after {
    width: 10px;
    height: 10px;
    position: absolute;
    content: "＋";
    left: 14px;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 12px;
    color: #666;
    font-weight: bold;
    transition: all 0.6s ease-out;
  }

  &.is-active {
    background-color: #707070;
    color: #fff;
    margin-bottom: 0;

    &::after {
      content: "";
      left: auto;
      border-right: 4px solid #fff;
      border-bottom: 4px solid #fff;
      right: 20px;
      transform: rotate(45deg);
    }

    &::before {
      left: 15px;
    }

    &.c-guest-join__ttl::before {
      // background-image: url($img_path + "modal/guest-icon-in.svg");
      background-image: url($img_path + "modal/guest-icon-in.png");
    }

    &.c-conditions__ttl::before {
      background-image: url($img_path + "modal/conditions-icon-in.png");
    }

    &.c-search-room__ttl::before {
      background-image: url($img_path + "modal/search-icon-in.png");
    }
  }

  &.is-listed {
    color: #fff;
    background-color: #707070;

    &::after {
      content: "";
      left: auto;
      border-right: 4px solid #fff;
      border-bottom: 4px solid #fff;
      right: 20px;
      transform: rotate(45deg);
    }

    &::before {
      left: 15px;
    }

    &.c-guest-join__ttl::before {
      background-image: url($img_path + "modal/guest-icon-in.png");
    }

    &.c-conditions__ttl::before {
      background-image: url($img_path + "modal/conditions-icon-in.png");
    }

    &.c-search-room__ttl::before {
      background-image: url($img_path + "modal/search-icon-in.png");
    }
  }

  &::before {
    position: absolute;
    content: "";
    left: 33px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-size: contain;
    transition: all 0.6s ease-out;
  }

  &.c-guest-join__ttl {
    &::before {
      width: 23px;
      height: 18px;
      background-image: url($img_path + "modal/guest-icon.svg");
    }
  }

  &.c-conditions__ttl {
    &::before {
      width: 11px;
      height: 23px;
      background-image: url($img_path + "modal/conditions-icon.svg");
    }
  }

  &.c-search-room__ttl {
    &::before {
      width: 19px;
      height: 19px;
      background-image: url($img_path + "modal/search-icon.svg");
    }
  }

  &.c-plan__ttl {
    font-size: 15px;
    padding-bottom: 10px;
    border-bottom: 2px solid #dfdfdf;
    margin-bottom: 10px;
    position: relative;
  }
}

.c-conditions__list-ttl {
  font-size: 11px;
  color: #666;
  margin-bottom: 10px;
}

/* テキスト
========================================================= */

.c-modal__lead-txt {
  font-size: 14px;
  font-weight: bold;
  color: #999999;
  margin: 20px 0 10px;
}

.c-modal-content__sub-ttl {
  font-size: 13px;
  font-weight: bold;
  color: #666666;
  margin: 15px 0 10px;
  #js_select-room_wrap & {
    font-size: 10px;
    margin: 6px 0 10px;
  }
}

.c-complete-ttl {
  font-size: 16px;
  letter-spacing: 3px;
  font-weight: bold;
  margin-bottom: 6px;
  color: #666;
  padding: 0 10px;

  &.c-select-room__ttl {
    margin-bottom: 15px;
  }
}

.c-myreserve-ttl__wrap {
  position: relative;
  margin-bottom: 2rem;
}
.is-reservation .c-myreserve-ttl__wrap {
  margin-bottom: 1rem;
  min-height: 4em;
}
.c-myreserve-main__ttl {
  max-width: 105px;
  padding: 0 7px 5px;
  border-bottom: 1px solid #fff;
  margin: 0 auto 5px;
}
.c-myreserve-sub__ttl {
  text-align: center;
  color: #fff;
  font-size: 8px;
  font-weight: bold;
}
.c-myreserve__info {
  font-size: 10px;
  font-weight: bold;
  max-width: 210px;
  margin: 0 auto 4em;
  letter-spacing: 0.1px;
  line-height: 1.2;
}
.c-myreserve__info_time {
  font-weight: normal;
  font-size: 20px;
  margin: 0 5px;
}
/*modal.html*/
.c-myreserve-modal__ttl {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.25rem;
  color: #666666;
}
.c-error__txt {
  color: #f00;
  display: none;
  margin-bottom: 5px;
}
.c-myreserve-modal-room__name {
  font-size: 17px;
  color: #000;
}
.c-myreserve-modal-room__sub-name {
  font-size: 10px;
  display: block;
}
.c-myreserve-modal-meeting__time {
  font-size: 23px;
  display: flex;
  align-items: center;
  line-height: 1;
  margin-bottom: 5px;
  color: #666;
}
.c-myreserve-modal-meeting__ttl {
  font-size: 14px;
  line-height: 1;
  margin-bottom: 2px;
}

.c-myreserve-modal-meeting__time-while {
  font-size: 17px;
  margin: 0 5px;
}
.c-myreserve-modal-meeting__name {
  word-break: break-all;
  font-size: 10px;
}
