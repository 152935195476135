@import "~vue-select/src/scss/vue-select.scss";

/* Extension
========================================================= */
@import "extension/variable"; // 変数・初期設定
@import "extension/css-variable"; // css variables
@import "extension/mixin"; // 変数・初期設定

/* keyframes
========================================================= */
@import "keyframes/vertical";

/* Foundation
========================================================= */
@import "foundation/ress"; // スタイル初期化
@import "foundation/base"; // デフォルトスタイル設定

/* Layoyt
========================================================= */
@import "layout/reserve-calendar";

/* Object
========================================================= */
/* Component
----------------------------------------------- */
@import "object/component/box";
@import "object/component/btn";
@import "object/component/card";
@import "object/component/form";
@import "object/component/heading";
@import "object/component/icon";
@import "object/component/label";
@import "object/component/link";
@import "object/component/list";
@import "object/component/nav";
@import "object/component/panel";
@import "object/component/search";
@import "object/component/section";
@import "object/component/table";
@import "object/component/text";
@import "object/component/visual";
@import "object/component/loading";
@import "object/component/app-version";

/* Project
-
---------------------------------------------- */
@import "object/project/reserve_modal";
@import "object/project/myreserve";
@import "object/project/login";

/* Utility
=======
----------------------------------------------- */
@import "object/utility/img"; // 画像
@import "object/utility/helper"; // ヘルパー

.tooltip {
  display: block !important;
  z-index: 10000;
}
.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}
.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}
.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}
.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}
.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[x-placement^="right"] {
  margin-left: 5px;
}
.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.tooltip[x-placement^="left"] {
  margin-right: 5px;
}
.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}
.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}
.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}
.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
