/* パネル
----------------------------------------------- */

.c-tabpanel_wrap {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 998;
  background-color: #fff;
}

.c-tab_list {
  display: flex;
  width: 100%;
  > li {
    width: 100%;
    min-width: 130px;
  }
}

.u-myreserve .c-tab_list {
  .is-selected {
    color: #fff;
    background-color: #65a5db;
  }
}

.u-conference .c-tab_list {
  .is-selected {
    color: #fff;
    background-color: #01a69b;
    border-bottom: 1px solid #01a69b;
  }
  .is-selected#pathToMyReserve {
    color: #fff;
    background-color: #65a5db;
    border-bottom: 1px solid #65a5d8;
  }
}

.c-tab_link {
  text-decoration: none;
  border-radius: 40px 40px 0 0;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 130px;
  height: 35px;
  padding: 8px 0;
  font-size: 1.3em;
  font-weight: 500;
  color: #707070;
  &.is-selected {
    color: #fff;
    color: pink;
  }
}

.c-panel_list {
  position: fixed;
  top: 52px;
  right: 0;
  left: 0;
  z-index: 998;
  > li {
    margin-top: -2px;
    padding-top: 8px;
    border-radius: 0 0 13px 13px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 5px 0px;
  }
}

.u-myreserve .c-panel_list {
  height: 45px;
  > li {
    height: 100%;
    padding: 16px 0;
    padding-left: 14px;
    background-color: #65a5db;
  }
}

.u-conference .c-panel_list {
  > li {
    height: 100%;
    min-height: 130px;
    padding: 11px 0 0;
    background-color: #01a69b;
    box-shadow: none;
    border-radius: 0;
  }
}
