/* アイコン
----------------------------------------------- */

.c-side-menu__date-icon {
  font-size: 9px;
  color: #01a69b;
  text-align: center;
  background-color: #fff;
  padding: 1px 0;
  width: 44px;
  border-radius: 13px;
  margin-left: 5px;
  position: absolute;
  right: -10px;
  bottom: 4px;
  display: none;
  &.is-today {
    display: initial;
  }
}

.c-select-room__box-svg {
  margin-left: 9px;
  transform: rotate(-90deg);
  fill: #fff;
  transition: all 0.6s ease-out;
  .is-open & {
    transform: rotate(90deg);
  }
}

.c-plan-textarea__svg {
  margin-right: 10px;
}

.c-myreserve-join__output .c-plan-textarea__svg,
.c-myreserve-join__info .c-plan-textarea__svg {
  fill: #fff;
}

.c-myreserve-room__box-svg {
  fill: #fff;
  transform: rotate(-90deg);
  transition: all 0.6s ease-out;
  .is-active & {
    transform: rotate(90deg);
  }
}

.c-myreserve-join__img {
  margin-right: 10px;
  width: 24px;
}

.r-select-room__iconUpDonw-wrap {
  width: auto;
  margin-right: 30px;
  padding: 10px 0;
}

.r-select-room__iconUpDonw {
  position: absolute;
  right: -30px;
  top: 6px;
  transform: none;
}

.r-select-room__iconUpDonw svg {
  transform: rotate(-90deg);
}

p.c-select-room__box-svg.r-select-room__iconUpDonw.is-open {
  right: -30px;
  top: 6px;
}

p.c-select-room__box-svg.r-select-room__iconUpDonw.is-open svg {
  transform: rotate(90deg);
}
