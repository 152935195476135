/* リスト
========================================================= */

/*Gカレンダー画面選択リスト*/

.g-change__list {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  margin: 0 auto 4px;
  > li {
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

.c-change_link {
  display: inline-block;
  font-size: 1.3em;
  color: #fff;
  padding: 0 1px 1.5px;
  &.is-selected {
    border-bottom: 1px solid #fff;
  }
}

/*reserve.html*/

.c-modal-list {
  display: flex;
  align-items: center;
  &.c-select-list {
    display: block;
    width: 100%;

    .u-ttl01 {
      z-index: 1000;
    }

    .u-ttl02 {
      z-index: 100;
    }

    .u-ttl03 {
      z-index: 10;
    }

    @include media(s) {
    }
    li:not(.vs__dropdown-option) {
      display: flex;
      align-items: center;
      padding: 8px 0;
      border-radius: 15px;
      border: 1px solid #cccccc;
      position: relative;
      margin-top: 20px;
      margin-bottom: 37px;
      max-width: 273px;
      width: 100%;
      &:last-child {
        margin-bottom: 0;
      }
      &.is-selected {
        border: 2px solid #01a69b;
      }
    }
    .c-select-cancel__btn-wrap {
      right: 3px;
      top: 5px;
    }
    .c-select-cancel__btn {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      &::before,
      &::after {
        width: 6px;
      }
    }
  }
  &.c-search-list {
    display: inherit;
    max-width: 256px;
    margin: 3rem auto;
    li:not(.vs__dropdown-option) {
      padding: 8px 0;
      border-radius: 3px;
      border: 2px solid #b3b3b3;
      border-radius: 15px;
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 3rem;
      &:last-child {
        margin-bottom: 0;
      }
      &.is-selected {
        border: 2px solid #01a69b;
      }
    }
  }
  &.c-myreserve-list {
    display: block;
    width: 100%;
    li:not(.vs__dropdown-option) {
      display: flex;
      align-items: center;
      padding: 8px 0;
      border-radius: 15px;
      border: 1px solid #fff;
      color: #fff;
      position: relative;
      margin-top: 20px;
      width: 100%;
      z-index: 900;
    }
  }
}

/*c-select*/

.c-select-candidate {
  max-width: 185px;
  position: absolute;
  top: -25px;
}

.c-select-info {
  padding-left: 10px;
  padding-right: 15px;
  border-right: 1px solid #b3b3b3;
  max-width: 120px;
  min-height: 64px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.c-select-info__room {
  font-size: 11px;
  border-bottom: 1px solid #b3b3b3;
  margin-bottom: 5px;
  color: #000;
  max-width: 80px;
  cursor: pointer;
  width: 80px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  @include media(s) {
    width: 50px;
  }
  &::after {
    // content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 1em;
    height: 1.4em;
    background: #fff;
    text-align: center;
  }
  .u-send & {
    pointer-events: none;
    cursor: none;
  }
}

.c-select-info__name {
  font-size: 8px;
  color: #666;
  line-height: 1.2;
}

.c-select-info__people {
  padding-left: 1.1rem;
  position: relative;
  font-size: 8px;
  color: #666;
  line-height: 10px;
  margin-top: 17px;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    width: 12px;
    height: 9px;
    background-size: contain;
    background-image: url($img_path + "common/people-icon.png");
  }
}

.c-select-daytime {
  max-width: 205px;
  padding-left: 10px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.c-select-daytime__date-label {
  position: relative;
  // margin-bottom: 1rem;
}

.c-select-daytime__date.afNone:after {
  content: none;
}

.c-select-daytime__date {
  font-size: 11px;
  border-bottom: 1px solid #b3b3b3;
  // margin-bottom: 1rem;
  color: #000;
  display: inline-block;
  max-width: 110px;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    right: 13px;
    top: 0;
    bottom: 0;
    margin: auto;
    border: none;
    width: 7px;
    height: 3px;
    border-top: 3px solid #000;
    border-right: 3px solid transparent;
    border-left: 3px solid transparent;
  }
  input {
    max-width: 7rem;
  }
}
.is-reservation .c-select-daytime__date {
  max-width: 110px;
}
.c-select-daytime__time-wrap {
  display: flex;
  align-items: center;
  width: 160px;
  @include media(xs) {
    width: 95%;
  }
  .c-select-daytime__time-while {
    margin: 0 7px;
  }
  .c-select-daytime__time-in,
  .c-select-daytime__time-out {
    width: 100%;
    font-size: 11px;
    border-bottom: 1px solid #b3b3b3;
    color: #000;
    display: inline-block;
    max-width: 65px;
    border-radius: 0;
  }
}

.c-select__release-btn__wrap {
  max-width: 70px;
  width: 100%;
  position: absolute;
  right: -80px;
  top: 0;
  overflow: hidden;
}

.c-select-cancel__btn-wrap {
  max-width: 20px;
  position: absolute;
  right: -1px;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.c-select-room__main-wrap {
  display: flex;
  padding: 0 14px 1.5em;
  border-bottom: 1px solid #cccccc;
  margin: 0 auto 2rem;
  width: 100%;
  .u-send & {
    border-bottom: none;
  }
}

.c-select__ttl-list {
  padding: 15px 14px 0 0;
  > li {
    height: 80px;
    font-size: 2.7em;
    line-height: 80px;
    margin: 20px 0 37px;
    color: #cccccc;
    &.is-active {
      color: #01a69b;
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

/*c-search*/

.c-search-candidate {
  position: absolute;
  top: -28px;
  width: 140px;
}

// .c-search-daytime {
//     max-width: 190px;
//     padding-left: 15px;
//     .c-search-daytime__date-label {
//         display: flex;
//         .c-search-daytime__date {
//             max-width: 125px;
//             font-size: 14px;
//             border-bottom: 1px solid #B3B3B3;
//             margin-bottom: 15px;
//             color: #000;
//             display: inline-block;
//         }
//         .c-search-daytime__date-icon {
//             width: 17px;
//             margin-left: 10px;
//             cursor: pointer;
//         }
//     }
// }

// .c-search-daytime__time-wrap {
//     display: flex;
//     align-items: center;
//     .c-search-daytime__time-while {
//         margin: 0 7px;
//     }
//     .c-search-daytime__time-in,
//     .c-search-daytime__time-out {
//         width: 75px;
//         font-size: 14px;
//         border-bottom: 1px solid #B3B3B3;
//         color: #000;
//         display: inline-block;
//     }
// }

// .c-search-cancel__btn-wrap {
//     width: 44px;
//     position: absolute;
//     right: -1px;
//     top: 0;
//     bottom: 0;
//     overflow: hidden;
// }

.c-search-candidate {
  position: absolute;
  top: -28px;
  width: 140px;
}

.c-search-daytime {
  max-width: 190px;
  padding-left: 28px;
  .c-search-daytime__date-label {
    display: flex;
    .c-search-daytime__date {
      max-width: 125px;
      font-size: 14px;
      border-bottom: 1px solid #b3b3b3;
      margin-bottom: 15px;
      color: #000;
      display: inline-block;
    }
    .c-search-daytime__date-icon {
      width: 17px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}

.c-search-daytime__time-wrap {
  display: flex;
  align-items: center;
  .c-search-daytime__time-while {
    margin: 0 7px;
  }
  .c-search-daytime__time-in,
  .c-search-daytime__time-out {
    width: 100%;
    max-width: 75px;
    font-size: 14px;
    border-bottom: 1px solid #b3b3b3;
    color: #000;
    display: inline-block;
  }
}

.c-search-cancel__btn-wrap {
  width: 44px;
  position: absolute;
  right: -1px;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

/*c-myreserve*/

.c-myreserve-candidate {
  position: absolute;
  top: -25px;
  text-align: left;
}

.c-myreserve-info {
  padding-left: 10px;
  padding-right: 20px;
  border-right: 1px solid #fff;
  max-width: 120px;
  min-height: 64px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include media(s) {
    padding-right: 10px;
  }
}

.c-myreserve-info__room {
  font-size: 11px;
  border-bottom: 1px solid #ffffff4d;
  margin-bottom: 5px;
  color: #fff;
  max-width: 65px;
  width: 80px;
  position: relative;
  height: 1.75em;
  overflow: hidden;
  text-align: justify;
  text-justify: inter-ideograph;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 1em;
    height: 1.4em;
    background: #65a5db;
    text-align: center;
  }
}

.is-inuse .c-myreserve-info__room::after {
  background: #ee9093;
}

.c-myreserve-info__name {
  font-size: 8px;
  color: #fff;
}

.c-myreserve-info__people {
  padding-left: 1.9em;
  position: relative;
  font-size: 8px;
  color: #fff;
  line-height: 10px;
  margin-top: 15px;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    width: 12px;
    height: 9px;
    background-size: contain;
    background-image: url($img_path + "myreserve/reserve-guest-icon-in.png");
  }
}

.c-myreserve-daytime {
  max-width: 205px;
  padding-left: 10px;
  margin-right: 15px;
}

.c-myreserve-daytime__date-label {
  position: relative;
  &::after {
    position: absolute;
    content: "";
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
    border: none;
    width: 3px;
    height: 0;
    border-top: 3px solid #fff;
    border-right: 3px solid transparent;
    border-left: 3px solid transparent;
  }
}

.c-myreserve-daytime__date {
  font-size: 12px;
  border-bottom: 1px solid #ffffff4d;
  margin-bottom: 14px;
  color: #fff;
  display: inline-block;
  width: 110px;
}

.c-myreserve-daytime__time-wrap {
  display: flex;
  align-items: center;
  width: 160px;
  z-index: 995;

  .c-myreserve-daytime__time-while,
  .c-select-daytime__time-while {
    margin: 0 7px;
  }
  .c-myreserve-daytime__time-in,
  .c-myreserve-daytime__time-out {
    width: 100%;
    font-size: 12px;
    border-bottom: 1px solid #ffffff4d;
    border-radius: 0;
    color: #fff;
    display: inline-block;
    max-width: 67px;
    line-height: 1.8;
    @include media(xs) {
      width: 38%;
    }
  }
}
.c-myreserve-box .c-select-daytime__date input {
  font-size: 12px;
  margin-bottom: 2px;
}
.is-reservation .c-myreserve-daytime__date input {
  font-size: 11px;
}
.c-myreserve-box .c-select-daytime__date::after {
  border-top-color: #fff;
  right: 0;
}
.is-reservation .c-myreserve-daytime__date {
  border-bottom: 1px solid rgb(179, 179, 179);
}

.is-reservation .c-myreserve-daytime__time-in,
.is-reservation .c-myreserve-daytime__time-out {
  border-bottom: 1px solid rgb(179, 179, 179);
  max-width: 62px;
  @include media(s) {
    width: 45%;
  }
  @include media(xs) {
    width: 25%;
  }
}

.c-myreserve__release-btn__wrap {
  max-width: 70px;
  width: 100%;
  position: absolute;
  right: -80px;
  top: 0;
  overflow: hidden;
}

/*c-modal*/

.c-modal__reserve-sub__list {
  margin-bottom: 40px;
  > dd,
  .c-modal__reserve-sub__list-item {
    max-width: 337px;
    margin: 0 auto;
    width: 100%;
    padding: 0 10px;
  }
}

.c-guest-join__info-wrap {
  display: inline-flex;
  align-items: center;
}

.c-guest-join__output {
  width: 100%;
  margin-top: 25px;
  padding-right: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 110px;
  > li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
    border-bottom: 1px solid #dfdfdf;
    margin-bottom: 10px;
    width: 100%;
    position: relative;
  }
}

.c-guest-join__color {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #999;
  margin-right: 10px;
}

.c-myreserve-join__output .c-guest-join__color,
.c-myreserve-join__info .c-guest-join__color {
  width: 15px;
  height: 15px;
  min-width: 15px;
  border: 1px solid #fff;
  margin-right: 18px;
}

.c-guest-join__name {
  letter-spacing: 0.36px;
  font-size: 12px;
  max-width: 105px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.c-guest-join_email {
  letter-spacing: 0.36px;
  font-size: 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #808080;
  width: calc(100% - 60px);
  margin-left: 35px;
  margin-bottom: 8px;
}
.c-myreserve__guest-join__box {
  max-width: 258px;
  .c-guest-join__name {
    // max-width: 150px;
    max-width: calc(100% - 10px);
  }
  .c-guest-join_email {
    color: #fff;
  }
}

.c-guest-join__candidate {
  display: flex;
  align-items: center;
  margin-left: calc(35px - 8px);
  margin-right: 10px;
  li {
    border: 1px solid #01a69b;
    display: flex;
    align-items: center;
    margin-left: 8px;
    &:last-child {
      margin-right: 0;
    }
    .c-guest-join__candidate-label {
      background-color: #01a69b;
      color: #fff;
      font-size: 10px;
      padding: 4px;
      width: 15px;
      text-align: center;
    }
  }
}

.c-guest-join__candidate-icon {
  padding: 2px;
  font-size: 10px;
  width: 15px;
  text-align: center;
  &.is-ok {
    color: #ed1c24;
  }
  &.is-no {
    color: #0000ff;
  }
  &.is-error {
    color: #08ff00;
  }
}

.c-conditions__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #b3b3b3;
  // padding-bottom: 16px;
  margin-bottom: 10px;
  margin-left: 0.5em;
  li {
    width: calc(50% - 15px);
    margin-right: 15px;
    font-size: 13px;
    color: #000;
    text-align: center;
    padding: 10px 0;
    background-color: #e6e6e6;
    margin-bottom: 15px;
    border-radius: 15px;
    cursor: pointer;
    &.is-active {
      background-color: #01a69b;
      color: #fff;
    }
  }
}

.c-guest-join__list-wrap {
  position: relative;
  margin-top: -10px;
}

.c-guest-join__list {
  display: none;
  position: absolute;
  max-width: 320px;
  min-width: 320px;
  z-index: 5001;
  left: auto;
  right: auto;
  top: auto;
  width: 300px;
  box-sizing: border-box;
  padding: 8px 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 0 0 2px 2px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  > li {
    max-height: 40px;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    margin-bottom: 5px;
    &.is-active {
      display: inherit;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.c-guest-join__img {
  width: 48px;
  padding-left: 16px;
  max-height: 40px;
  height: 24px;
  > img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
}

.c-guest-join__box {
  padding: 4px 0;
  width: 100%;
  max-width: 337px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 24px;
  .c-guest-join__name {
    width: 100%;
    min-width: 300px;
    font-size: 15px;
  }
  .c-guest-join__mail {
    width: 100%;
    min-width: 300px;
    font-size: 12px;
  }
}

.c-myreserve-join__output {
  > dt {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 10px;
  }
  > dd {
    transition: all 0.6s ease-out;
    .c-guest-join__output {
      margin-top: 0;

      > li {
        justify-content: flex-start;
        padding-right: 10px;
      }
    }
  }
}
.c-myreserve__guest-join__box .c-guest-join__output {
  padding-right: 0;
}

/* dlが未設定用 */

.c-myreserve-join__info {
  font-size: 10px;
  .c-myreserve-join__label {
    margin: 1rem 0 1rem auto;
    padding-right: 1.5em;
    position: relative;
    text-align: right;
  }
  .c-myreserve-room__box-svg {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .c-myreserve-join__wrap {
    width: 100%;
  }
  .c-myreserve-join__info-box {
    .c-guest-join__output {
      margin-top: 0;

      > li {
        justify-content: flex-start;
        padding-right: 10px;
      }
    }
  }
}
