/* フォーム
========================================================= */

.c-nav__checkbox-label {
  margin-bottom: 10px;
}

.c-nav__checkbox-label:last-child {
  margin-bottom: 0;
}

.c-nav__checkbox {
  display: none;

  + .c-nav__check--txt {
    display: inline-block;
    padding-left: 24px;
    position: relative;
    cursor: pointer;
    font-size: 1.4em;
    font-weight: 500;
    color: #707070;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 15px;
      height: 15px;
      border: 1px solid #b3b3b3;
    }
  }

  &:checked + .c-nav__check--txt {
    &::before {
      border: none;
    }

    &.u-color01::before {
      background-color: #039be5;
    }

    &.u-color02::before {
      background-color: #3f51b5;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: -3px;
      left: 3px;
      bottom: 0;
      margin: auto;
      width: 8px;
      height: 11px;
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
      transform: rotate(45deg);
    }
  }
}

/*reserve.html */
.c-modal-main__ttl {
  border-radius: 34px;
  background-color: #f2f3f4;
  color: #3c4043;
  max-width: 284px;
  width: calc(100% - 60px);
}
.c-modal-ttl__input {
  color: #3c4043;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 1.5em;
  padding: 7px 15px;
  width: 100%;
}

.c-plan-textarea__wrap {
  margin: 0 auto;
  padding: 0 2rem 2rem;
  // max-width: 290px;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: visible;
  justify-content: stretch;
}
.c-myreserve-join__output .c-plan-textarea__wrap,
.c-myreserve-join__info .c-plan-textarea__wrap {
  align-items: stretch;
  padding: 0 0 2rem;
}
/*reserve.html*/
.c-plan-textarea {
  overflow-y: scroll;
  height: 70px;
  padding: 8px;
  font-size: 11px;
  background-color: #f1f3f4;
  width: 100%;
  border-radius: 4px;
}

.c-guest-join__input input {
  font-size: 13px;
  border: none;
  background-color: #f1f3f4;
  border-radius: 34px;
  width: 100%;
  height: 35px;
  padding: 7px 0 7px 16px;
  position: relative;
}
.c-conditions-people {
  font-size: 12px;
  border-bottom: 1px solid #b3b3b3;
  width: 20px;
  margin: 0 4px;
  text-align: center;
}
.c-conditions__check {
  display: none;

  + .c-conditions__check-label {
    display: inline-block;
    padding-left: 24px;
    position: relative;
    cursor: pointer;
    font-size: 13px;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -3px;
      left: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #b3b3b3;
    }
  }

  &:checked + .c-conditions__check-label::after {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 0;
    margin: auto;
    width: 15px;
    height: 11px;
    background-size: contain;
    background-image: url($img_path + "modal/check-icon.png");
  }
}
.c-myreserve-modal__select-time {
  display: flex;
  align-items: center;
  max-height: 287px;
  margin: 0 auto;
  font-size: 23px;
  margin-bottom: 45px;
  text-align: center;
  color: #666;
}
.c-myreserve-modal__select-input {
  padding-bottom: 5px;
  border-bottom: 2px solid #707070;
  width: 100%;

  &.c-myreserve-modal__select-time__in,
  &.c-myreserve-modal__select-time__out {
    max-width: 115px;
  }
}
.c-myreserve-modal__select-time__while {
  margin: 0 15px;
}

/* フォーム
========================================================= */

.c-plan__output {
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
  margin-bottom: 2rem;
  justify-content: flex-end;

  .c-plan__mail {
    font-size: 13px;
  }

  .c-plan-select__color-wrap {
    position: relative;

    .c-plan-select__color-top {
      position: absolute;
    }

    .c-plan-select__color {
      // width: 55px;
      background-color: #f1f3f4;
      padding: 5px;
      position: relative;
      cursor: pointer;
      margin-left: 10px;
      transition: background 0.3s;
      color: #444;
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      outline: none;
      overflow: hidden;
      position: relative;
      text-align: center;

      &::after {
        position: absolute;
        content: "";
        background-image: url($img_path + "modal/contact-select_icon.png");
        background-size: contain;
        width: 9px;
        height: 9px;
        right: 5px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    .c-plan-option__wrap {
      display: flex;
      align-items: center;

      .c-plan-option {
        width: 18px;
        height: 18px;
        margin: 3px;

        .c-paln-option__check {
          opacity: 0;
          transition: opacity 100ms linear;
          color: #fff;
          display: block;
          width: 18px;
          height: 18px;
          text-align: center;
          pointer-events: none;
          position: relative;

          &.is-active {
            opacity: 1;
            pointer-events: auto;
          }

          &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            width: 5px;
            height: 12px;
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            transform: rotate(45deg);
          }
        }

        .c-plan-option__inner {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
    }

    .c-plan-option {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      // margin-right: 10px;
      display: inline-block;
      outline: none;
      cursor: pointer;
      position: relative;
      background-color: #999;

      &.u-color01 {
        background-color: #d50000;
      }

      &.u-color02 {
        background-color: #e67c73;
      }

      &.u-color03 {
        background-color: #f4511e;
      }

      &.u-color04 {
        background-color: #f6bf26;
      }

      &.u-color05 {
        background-color: #33b679;
      }

      &.u-color06 {
        background-color: #0b8043;
      }

      &.u-color07 {
        background-color: #039be5;
      }

      &.u-color08 {
        background-color: #3f51b5;
      }

      &.u-color09 {
        background-color: #7986cb;
      }

      &.u-color10 {
        background-color: #8e24aa;
      }

      &.u-color11 {
        background-color: #616161;
      }
    }
  }

  .c-plan-select__color-box {
    position: absolute;
    max-width: 64px;
    max-height: 160px;
    transform: translateZ(0);
    transition: max-width 0.2s cubic-bezier(0, 0, 0.2, 1),
      max-height 0.2s cubic-bezier(0, 0, 0.2, 1), opacity 0.1s linear;
    background: #ffffff;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    opacity: 1;
    outline: 1px solid transparent;
    z-index: 2000;
    margin-top: -2px;

    .c-plan-select__color-box__inner {
      max-width: 64px;
      max-height: 160px;
      width: auto;
      height: auto;
      min-width: auto;
      padding: 8px;
    }
  }
}
