html {
    min-height: 100vh;
}

body {
    -webkit-text-size-adjust: 100%;
}

.rooms {
    transform: translateX(1px);
}


/*--
js_vue_drag_scroll_wrap
--*/

.time_wrap {
    background: #01A69B;
    position: sticky;
    top: 0;
    border-radius: 0 0 15px 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px 0px;
    z-index: 99;
    overflow: hidden;
    padding-left: 5px;
}

.time_wrap .c-calender__vue_drag {
    background: #01A69B;
    position: relative;
}

.time_wrap::before {
    position: absolute;
    content: "";
    width: 100vw;
    height: 10px;
    top: -5px;
    background-color: #01A69B;
}

.js_vue_drag_scroll_wrap {
    overflow: hidden;
    width: calc(100% - 120px);
    line-height: 0;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: grab;
    position: sticky;
    top: 0;
    background: #fff;
}

.js_vue_drag_scroll_wrap:active {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: grabbing;
}

.js_vue_drag_scroll_child {
    /* width: 384px; */
}

.c-calender__vue_drag {
    width: calc(100% - 105px);
    margin-left: auto;
    padding-left: 15px;
    z-index: 100;
    height: 50px;
    position: relative;
}
.time_wrap::after {
    position: absolute;
    content: "";
    background-color: #01A69B;
    bottom: 0;
    left: 107px;
    height: 20px;
    width: 15px;
    z-index: 100;
}
@media only screen and (min-width:376px) {
    /* .c-calender__vue_drag {
        width: 280px;
    }
    .js_vue_drag_scroll_wrap {
        width: 280px;
    } */
}

@media only screen and (max-width:360px) {
    /* .c-calender__vue_drag {
        width: 230px;
    }
    .js_vue_drag_scroll_wrap {
        width: 230px;
    } */
    .c-myreserve-modal__content .c-myreserve-modal__select-time {
        font-size: 22px;
    }
}

@media only screen and (max-width:320px) {
    .c-calender__vue_drag {
        width: 191px;
    }
    .js_vue_drag_scroll_wrap {
        width: 191px;
    }
    .c-myreserve-modal__content .c-myreserve-modal__select-time {
        font-size: 19px;
    }
}

.ac>label {
    display: block;
}

.ac>input[type="checkbox"] {
    display: none;
}

.ac>input[type="checkbox"]~div {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    transition: .5s;
}

.ac>input[type="checkbox"]:checked~div.te {
    overflow: visible;
}

.ac>input[type="checkbox"]:checked~label:after {
    transform: rotate(225deg);
}

.ac>input[type="checkbox"]:not(:checked)+.c-select-room__box-ttl {
    padding: 12px;
}

.ac>input[type="checkbox"]:not(:checked)~div {
    opacity: 0;
    height: 0 !important;
}

.ac>input[type="checkbox"]:not(:checked)~div.c-select-room__box {
    margin: 0;
    padding-bottom: 0;
}

.c-conditions-people .vs__dropdown-toggle {
    width: 100%;
    height: 20px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    border-radius: 0px;
}

.c-conditions-people .vs__clear {
    display: none;
}


/*--
vue-select
--*/

.vs__dropdown-menu {
    z-index: -1;
}
.p-guest-join__box .vs__dropdown-menu {
    min-width: 300px;
}
.c-select-room__box-wrap .vs__actions,
.c-modal__reserve-sub__list-item .vs__actions,
.p-modal__mail-info .vs__actions,
.c-myreserve-modal__content .vs__actions,
.c-myreserve-join__wrap .vs__actions {
    display: none;
}

.c-select-room__box-wrap .vs__dropdown-toggle,
.c-modal__reserve-sub__list-item .vs__dropdown-toggle,
.p-modal__mail-info .vs__dropdown-toggle,
.c-myreserve-modal__content .vs__dropdown-toggle,
.c-myreserve-join__wrap .vs__dropdown-toggle {
    border: none;
    padding: 0;
}

.c-modal__reserve-sub__list-item .c-search-daytime__time-wrap .vs__dropdown-menu {
    bottom: 2em;
    top: auto;
}

.c-myreserve-box.is-reservation .vs__selected-options {
    z-index: -1;
}

.vs--open .vs__dropdown-menu {
    z-index: 1001 !important;
}

.c-select-room__box-wrap .vs__selected,
.p-modal__mail-info .vs__selected,
.c-myreserve-modal__content .vs__selected {
    position: absolute;
    width: 155px;
    top: 0;
}
.c-myreserve-modal__select-input.c-myreserve-modal__select-time__in .vs__selected, .c-myreserve-modal__select-input.c-myreserve-modal__select-time__out .vs__selected {
    color: #666;
}
@media only screen and (max-width:320px) {
    .c-select-room__box-wrap .vs__selected {
        width: auto;
    }
    .c-select-room__box-wrap .vs__dropdown-toggle {
        padding-bottom: 14px;
    }
}

.c-modal__reserve-sub__list-item .vs__selected,
.p-myreserve-info__modal .vs__selected {
    position: absolute;
    width: 165px;
    top: 0;
    margin: 0;
    padding: 0;
}
.class_meeting_room_wrap .c-modal-list.c-select-list li:not(.vs__dropdown-option) .test {
    border: 1px solid #b3b3b3;
}

.class_meeting_room_wrap .c-modal-list.c-select-list li:not(.vs__dropdown-option).is-selected .test {
    border-color: rgb(1, 166, 155);
    border-width: 2px;
}

.class_meeting_room_wrap .c-modal-list.c-select-list li:not(.vs__dropdown-option).is-old .test,
.class_meeting_room_wrap .c-modal-list.c-select-list li:not(.vs__dropdown-option).is-booked .test {
    border-color: #c14848 !important;
}

.class_meeting_room_wrap .c-modal-list.c-select-list.u-send .c-select__release-btn__wrap,
.class_meeting_room_wrap .c-modal-list.c-select-list.u-send .c-select-cancel__btn-wrap {
    display: none;
}

.u-ttl01.selected-area.is-old,
.u-ttl01.selected-area.is-booked {
    margin-bottom: 47px !important;
}

.u-ttl01.selected-area.is-old:nth-child(3),
.u-ttl01.selected-area.is-booked:nth-child(3) {
    margin-bottom: 27px !important;
}


/* v-modal */

.p-modal__mail-info .v--modal-box.v--modal {
    border-radius: 10px;
    max-width: 337px;
    width: 95%;
    padding-top: 10px;
    overflow: hidden;
    margin: 0 auto;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.2);
}

.p-modal__mail-info .c-modal-list {
    pointer-events: none;
}
.vdp-datepicker__calendar {
    color: #808080 !important;
}
.p-modal__mail-info .vdp-datepicker__calendar,
#js_select-room_wrap .vdp-datepicker__calendar {
    left: -8rem;
}

#js_check_wrap .vdp-datepicker__calendar {
    left: -2rem;
    bottom: 7vh;
}
#js_check_wrap .c-search-new-select__date.select__max_date .vdp-datepicker__calendar {
    right: -2em;
    left: auto;
}

.c-side-menu__date .vdp-datepicker__calendar {
    top: 1.5em;
}

.p-myreserve .v--modal-box.v--modal,
.p-myreserve-info__modal .v--modal-box.v--modal {
    border-radius: 20px;
    max-width: 337px;
    margin: 0 auto 2rem;
    padding: 12px 0 20px;
    -webkit-box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.2);
    position: relative;
}

.p-myreserve-info__modal .v--modal-box.v--modal {
    left: auto !important;
}
.p-myreserve-info__modal .v--modal-overlay {
    z-index: 1000;
}
.sideMenu.v--modal-overlay {
    z-index: 2001 !important;
}
.p-myreserve-info__modal .c-myreserve-modal-btn__wrap.is-tentative {
    max-width: 140px;
}
.p-myreserve-info__modal .c-myreserve-modal-btn__wrap.is-tentative .c-btn.c-myreserve-modal-btn {
    background-color: #01A69B;
}
.p-reserv-modal .v--modal-box.v--modal {
    top: 0 !important;
    overflow-y: scroll;
}

.p-extension__modal .v--modal-box.v--modal {
    overflow: visible !important;
}

.loading {
    background-color: #fff;
    padding: 30px 30px 15px;
    overflow: hidden;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.2);
}

.loading span {
    line-height: 2;
    font-size: 16px;
}

.p-modal__loading .v--modal-box.v--modal {
    padding: 0;
    border: none;
    background: none;
    border-radius: 0;
    box-shadow: none;
}

.guestInput-select {
    margin-bottom: 10px;
}

.guestInput-select #vs1__combobox {
    border: 0;
}

.guestInput-select .vs__selected-options input {
    background-color: #f1f3f4 !important;
}

.c-guest-list-wrap .c-guest-cancel__btn:before,
.c-guest-list-wrap .c-guest-cancel__btn:after {
    border-color: #fff;
}

.plan-textarea_focus .c-plan-textarea:focus {
    outline: none;
}

.c-myreserve-edit__btn-box.is-active svg {
    display: none;
}

.myreserve-daytime-select {
    position: relative;
    z-index: 10;
}

.myreserve-daytime-select .vs--unsearchable {
    max-width: 75px !important;
}

.myreserve-daytime-select .vs__dropdown-toggle {
    border: 0;
    padding: 0;
}

.myreserve-daytime-select .vs__dropdown-toggle .vs__selected {
    color: #fff;
    margin: 0;
    margin-bottom: 2px;
    padding: 0;
}

.myreserve-daytime-select .vs__selected-options {
    justify-content: center;
}

.myreserve-daytime-select .vs__selected-options input {
    position: absolute;
    z-index: -1;
}

.myreserve-daytime-select .vs__actions {
    display: none;
}

.myreserveDatePicker {
    border-bottom-color: #ffffff4d !important;
}

.myreserveDatePicker .vdp-datepicker__calendar {
    left: 50%;
    margin-left: -150px;
}

.myreserveDatePicker input {
    color: #fff;
}

.myreserveDatePicker.c-select-daytime__date::after {
    border-top-color: #fff;
}

.tentativeList-wrap .c-myreserve-daytime__date,
.tentativeList-wrap .c-myreserve-daytime__time-in,
.tentativeList-wrap .c-myreserve-daytime__time-out,
.tentativeList-wrap .myreserveDatePicker input,
.tentativeList-wrap .vs__selected {
    color: #666 !important;
}

.tentativeList-wrap .c-select-info__room {
    width: 63px;
    padding-bottom: 2px;
    font-weight: normal;
}

.tentativeList-wrap .vs__selected {
    border-bottom: 1px solid #666;
}

.tentativeList-wrap .myreserve-daytime-select {
    /* margin-top: 10px; */
    z-index: auto;
}

.tentativeList-wrap .c-select-daytime__date-label {
    display: inline-block;
}

.tentativeList-wrap .myreserveDatePicker,
.tentativeList-wrap .myreserveDatePicker input {
    margin-bottom: 0 !important;
}

.tentativeList-wrap .myreserveDatePicker.c-select-daytime__date::after {
    right: 4px;
    border-top-color: #666;
}

.standBy-cancelBtn {
    background: #65A5DB !important;
}

.c-adding_fixed-conditions .v-select.c-conditions-people {
    width: 18px;
}

.c-adding_fixed-conditions .vs__dropdown-toggle {
    height: auto;
    padding-bottom: 0;
}

.c-adding_fixed-conditions .vs__search {
    min-width: 20px;
    padding: 0 !important;
    margin-top: 0 !important;
}

.c-select__ttl-list .is-old,
.c-select__ttl-list .is-booked {
    color: #c14848 !important;
}

.c-select-list.c-modal-list .is-old,
.c-select-list.c-modal-list .is-booked {
    border-color: #c14848 !important;
}

.c-select-list.c-modal-list li .candidateRoomDetail-box .c-select-info__room {
    width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1;
}

.c-select-list.c-modal-list .is-old .c-select-info__room,
.c-select-list.c-modal-list .is-old .c-select-info__name,
.c-select-list.c-modal-list .is-old .c-select-info__people,
.c-select-list.c-modal-list .is-old .c-select-daytime__date input,
.c-select-list.c-modal-list .is-old .c-select-daytime__time-wrap .vs__selected ,
.c-select-list.c-modal-list .is-old .c-select-daytime__time-wrap .c-select-daytime__time-in,
.c-select-list.c-modal-list .is-old  .c-select-daytime__time-wrap .c-select-daytime__time-out {
    color: #c14848;
}

.c-select-list.c-modal-list .is-booked .c-select-info__room,
.c-select-list.c-modal-list .is-booked .c-select-info__name,
.c-select-list.c-modal-list .is-booked .c-select-info__people,
.c-select-list.c-modal-list .is-booked .c-select-daytime__date input,
.c-select-list.c-modal-list .is-booked .c-select-daytime__time-wrap .vs__selected,
.c-select-list.c-modal-list .is-booked .c-select-daytime__time-wrap .c-select-daytime__time-in,
.c-select-list.c-modal-list .is-booked  .c-select-daytime__time-wrap .c-select-daytime__time-out {
    color: #c14848;
}

.c-select-list.c-modal-list .is-old .c-select-info__room,
.c-select-list.c-modal-list .is-old .c-select-daytime__date,
.c-select-list.c-modal-list .is-old .vs--single,
.c-select-list.c-modal-list .is-old .c-select-daytime__time-wrap .c-select-daytime__time-in,
.c-select-list.c-modal-list .is-old  .c-select-daytime__time-wrap .c-select-daytime__time-out {
    border-color: #c14848 !important;
}

.c-select-list.c-modal-list .is-booked .c-select-info__room,
.c-select-list.c-modal-list .is-booked .c-select-daytime__date,
.c-select-list.c-modal-list .is-booked .vs--single,
.c-select-list.c-modal-list .is-booked .c-select-daytime__time-wrap .c-select-daytime__time-in,
.c-select-list.c-modal-list .is-booked  .c-select-daytime__time-wrap .c-select-daytime__time-out {
    border-color: #c14848 !important;
}

.c-select-list.c-modal-list .is-old .c-select__release-btn.is-active,
.c-select-list.c-modal-list .is-booked .c-select__release-btn.is-active {
    background: #c14848;
    border-color: #c14848;
}

.c-select-list.c-modal-list .is-old,
.c-select-list.c-modal-list .is-booked {
    margin-bottom: 46px;
}

.c-select-list.c-modal-list .is-booked .notice-booked {
    position: absolute;
    left: 0;
    bottom: -28px;
    color: #c14848;
    font-size: 16px;
}
.c-select-list.c-modal-list .is-old .notice-time {
    position: absolute;
    left: 0;
    bottom: -20px;
    color: #c14848;
    font-size: 12px;
}

.border-wrap {
    display: flex;
    width: 100%;
    align-items: center;
}

.border-wrap.is-selected {
    padding: 8px 0;
    border: 2px solid #01A69B;
    border-radius: 15px;
}

.border-wrap+.c-select__release-btn__wrap {
    position: relative;
    max-width: none;
    top: auto !important;
    text-align: right;
}

.border-wrap+.c-select__release-btn__wrap .c-select__release-btn {
    display: inline-block;
    max-width: 70px;
    margin-top: 5px;
}

.roomRecommendsAlphabet {
    position: absolute;
    top: 30%;
    left: -30px;
    color: #01A69B;
    font-size: 2.7em;
}

.tentativeEditActive .roomRecommendsAlphabet {
    margin-top: -10px;
}

.c-btn.c-myreserve__btn.checkInBtn {
    display: none;
}

.checkInBtnActive .c-btn.c-myreserve__btn.checkInBtn {
    display: block;
}

.animateTransist {
    position: relative !important;
    transition: .95s;
}

.animateOverWrap .animateTransist {
    z-index: 10;
}

.animateTopUp {
    /* transform: translateY(117px); */
    transform: translateY(7.11rem);
    transform: translateY(148%);
}

@media only screen and (max-width:350px) {
    .animateTopUp {
        transform: translateY(7.5rem);
        transform: translateY(151.5%);
    }
}

#js_select-room_wrap .c-select-info {
    min-height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 5px;
}

@media only screen and (max-width:350px) {
    .c-modal-list.c-select-list .c-select-cancel__btn-wrap {
        right: -7px !important;
    }
}

.is-selected.animateTransist,
.is-selected .c-select-candidate {
    z-index: 20;
    background: #fff;
}

.animateOverWrapBox .c-select-room__main-wrap {
    position: relative;
    margin-bottom: 0;
}

.animateOverWrapBox .c-select-room__main-wrap.animateOverWrap {
    z-index: 10;
}

.animateOverWrapBox .c-plan-textarea__wrap {
    position: relative;
    padding: 20px 20px 30px;
    margin: 0;
    border-radius: 20px;
    background: #fff;
}

.animateOverWrap+.c-plan-textarea__wrap {
    z-index: 20;
}

.c-calendar__table-wrap .c-calendar__table-main__wrap {
    background-color: #fff;
}
.c-btn.c-myreserve-modal-btn.standBy-cancelBtn {
    padding: 10px 8px;
}

#js_reservation_wrap {
    height: 100vh;
    width: 100vw;
}

#js_reservation_wrap .c-guest-cancel__btn {
    top: 0;
    height: auto;
    margin: 7px 0;
}
#js_check_wrap .p-search-new__box-wrap .vs__selected {
    font-size: 12.5px;
}
#js_check_wrap .c-search-new-select__box input {
    font-size: 11px;
}
@media only screen and (max-width:350px) {
    #js_check_wrap .c-search-new-select__box input {
        font-size: 10px;
    }
}
#js_reservation_wrap .c-guest-join__info-wrap{
    width: calc(100% - 35px);
}
#js_reservation_wrap .c-guest-join__info-wrap .c-guest__tooltips{
    width: 100%;
}
/* _:lang(x)+_:-webkit-full-screen-document , .c-calendar__table-sub tr td:last-child {
    padding-right: 25px;
} */

.c-myreserve-box.is-reservation.tentativeEditActive .c-myreserve-join__img{
    margin-top: 10px;
}
.c-guest-join__input input.vs__search,
.c-guest-join__input input.vs__search:focus {
    background-color: #f1f3f4;
}
#js_check_wrap .ac > input[type=checkbox]:not(:checked) ~ div {
    margin-top: 40px;
}
_:lang(x)+_:-webkit-full-screen-document, #js_detail_wrap .c-modal__reserve-sub__list-item .vs__selected-options {
    padding: 0;
}
 #js_detail_wrap .c-modal__reserve-sub__list-item .vs__selected {
    width: 20px;
    display: block;
}
_:lang(x)+_:-webkit-full-screen-document, #js_detail_wrap .c-modal__reserve-sub__list-item .vs__selected {
    top: 3px;
}
_:lang(x)+_:-webkit-full-screen-document, .time_wrap {
    position: relative;
}
_:lang(x)+_:-webkit-full-screen-document, .c-calendar__table-sub tr::after {
    left: 0;
    min-width: 960px;
}
.myreserveDatePicker {
    margin-bottom: 15px;
}
_:lang(x)+_:-webkit-full-screen-document, .c-select-daytime__date::after {
    right: 10px;
}
