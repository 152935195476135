/* マイリザーブ
========================================================= */
.p-myreserve.detail-list {
  margin-top: 7rem;
  // margin-bottom: 6rem;
}
.c-myreserve-join__wrap {
  margin: 0 auto 2em;
  max-width: 290px;
  display: flex;
}
.editActive .c-myreserve-join__wrap {
  align-items: baseline;
}
/*modal*/
.p-myreserve-modal {
  /* border-radius: 20px;
    max-width: 337px;
    width: 95%;
    overflow: hidden;
    margin: 0 auto 2rem;
    padding: 12px 0;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.2);
    position: relative; */
}
.c-myreserve-modal__content {
  padding: 0 24px;
}
.c-myreserve-modal-btn__wrap {
  // max-width: 130px;
  max-width: 116px;
  margin: 0 auto;
}

/* 仮予約部分 */
.c-myreserve-box {
  &.is-reservation {
    border: 2px solid #01a69b;
    padding: 18px 15px;
    color: #666;

    &.no-edit {
      max-width: 490px;
    }

    .c-myreserve-main__ttl {
      border-color: #666;
    }

    .c-myreserve-sub__ttl {
      color: #666666;
    }

    .c-label.c-candidate.c-myreserve-candidate-ttl {
      color: #666666;
    }

    .c-myreserve-list {
      li:not(.vs__dropdown-option) {
        color: initial;
        margin-top: 0;

        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        @include media(s) {
          max-width: 265px;
        }
      }

      .c-select-room__box {
        display: flex;
      }
      .c-select-list {
        @include media(xs) {
          width: 85%;
        }
      }
      .c-select-info {
        padding-right: 10px;
      }
      .c-select__ttl-list {
        padding-top: 0;

        > li {
          color: #01a69b;
        }
      }

      .c-select__release-btn__wrap {
        top: 85px;
        right: 0;
      }
      .c-select__release-btn {
        padding: 3px 0;
      }

      .c-select-daytime__date-label {
        // border-bottom: 1px solid #000;
        border-bottom: 1px solid #b3b3b3;
        margin-bottom: 1rem;
      }

      .c-select-daytime__time-wrap {
        .c-select-daytime__time-in,
        .c-select-daytime__time-out {
          border-bottom: 1px solid #000;
          @include media(s) {
            width: 35%;
          }
        }
      }
    }

    .c-myreserve-join__output {
      > dt {
        color: #666;
        cursor: pointer;
      }
    }

    .c-myreserve-room__box-svg {
      fill: #666;
    }

    .c-myreserve-join__wrap {
      margin-top: 27px;
    }

    .c-guest-join__name {
      color: #666;
      min-width: 65px;
    }
    .c-guest-join_email {
      color: #666;
    }

    .c-myreserve-join__output {
      .c-plan-textarea__svg {
        fill: inherit;
      }
    }

    .c-myreserve__btn {
      background-color: #01a69b;
      max-width: 120px;
    }

    .c-guest-cancel__btn {
      &::before,
      &::after {
        border-color: #666;
      }
    }
  }
}
.c-myreserve-cancel__btn {
  position: absolute;
  width: 27px;
  height: 27px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px 0px;
  padding: 5px 0;
  text-align: center;
  background-color: #999;
  border-radius: 50%;
  right: 16px;
  cursor: pointer;
  z-index: 10;
  &.no-edit {
    display: none;
    z-index: 0;
  }

  &::before {
    position: absolute;
    content: "";
    width: 12px;
    height: 1px;
    border-top: 2px solid #fff;
    transform: rotate(45deg);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  &::after {
    position: absolute;
    content: "";
    width: 12px;
    height: 1px;
    border-top: 2px solid #fff;
    transform: rotate(-45deg);
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
  }
}

.c-myreserve-edit__btn {
  position: absolute;
  width: 27px;
  height: 27px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px 0px;
  padding: 5px 0;
  text-align: center;
  background-color: #fff;
  background-position: center;
  border-radius: 50%;
  right: 16px;
  cursor: pointer;
  z-index: 10;

  .c-myreserve-edit__svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    fill: #fff;
  }

  &.is-active {
    opacity: 0.6;
  }
}
.is-reservation .c-myreserve-edit__btn {
  background-color: #999;
}
.is-standby .c-myreserve-cancel__btn,
.is-inuse .c-myreserve-cancel__btn {
  background-color: #fff;
}
.is-standby .c-myreserve-edit__svg {
  fill: #65a5db;
}
.is-standby .c-myreserve-cancel__btn::before,
.is-standby .c-myreserve-cancel__btn::after {
  border-top: 2px solid #65a5db;
}
.is-inuse .c-myreserve-edit__svg {
  fill: #ee9093;
}
.is-inuse .c-myreserve-cancel__btn::before,
.is-inuse .c-myreserve-cancel__btn::after {
  border-top: 2px solid #ee9093;
}
