/* ボックス
----------------------------------------------- */

/*カレンダー*/

.c-date-select {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.u-conference .c-date-select {
  z-index: 105;
  width: 125px;
  margin-top: 2px;
  margin-bottom: -2px;
}

.c-date-select__txt {
  width: 110px;
  padding-left: 8px;
  font-size: 1.5em;
  padding-right: 1em;
  position: relative;
  display: block;
  &::after {
    position: absolute;
    content: "";
    width: 3px;
    height: 0px;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    border: none;
    border-right: 3px solid transparent;
    border-left: 3px solid transparent;
    border-top: 5px solid #fff;
    transition: all 0.3s;
  }
  &.is-active::after {
    transform: rotate(180deg);
  }
}

.datePicker_input {
  width: 100%;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  &:focus {
    outline: none;
  }
}
.is-today .datePicker_input {
  color: #01a69b;
}

.c-date-calendar__box {
  display: none;
  position: absolute;
  top: 30px;
  padding: 10px 10px 16px;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  @include media(m) {
    padding: 10px 20px 16px;
  }
  &.is-active {
    display: inherit;
  }
}

.c-date-calendar__week {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  > li {
    width: calc((100% - 180px) / 7);
    text-align: center;
    margin-right: 30px;
    font-size: 1.4em;
    &:last-child {
      margin-right: 0;
    }
    &.is-today {
      color: #1a73e8;
    }
    @include media(m) {
      width: 27px;
      height: 27px;
      line-height: 27px;
    }
  }
}

.c-date-calendar__date {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  > li {
    width: calc((100% - 180px) / 7);
    text-align: center;
    height: 24px;
    line-height: 24px;
    margin-bottom: 10px;
    margin-right: 30px;
    font-size: 1.4em;
    @include media(xs) {
      height: 1.25em;
      line-height: 1.25em;
    }
    @include media(m) {
      width: 27px;
      height: 27px;
      line-height: 27px;
    }
    &:nth-child(7n) {
      margin-right: 0;
    }
    &.is-today {
      background-color: #1a73e8;
      color: #ffffff;
      border-radius: 50%;
      .c-date-calendar__task {
        display: none;
      }
    }
  }
}

.c-date-calendar__task {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 0;
  width: 100%;
  .c-date-calendar__task-name {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 0 2px;
    @include media(s) {
      width: 4px;
      height: 4px;
      margin: 0 1px;
    }
    &.u-color01 {
      background-color: #039be5;
    }
    &.u-color02 {
      background-color: #0b8043;
    }
    &.u-color03 {
      background-color: #d50000;
    }
    &.is-4more {
      position: relative;
      border-radius: 0;
      &::before {
        position: absolute;
        content: "";
        width: 5px;
        height: 1px;
        border-bottom: 1px solid #3c4043;
        transform: rotate(90deg);
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        @include media(s) {
          width: 4px;
        }
      }
      &::after {
        position: absolute;
        content: "";
        width: 5px;
        height: 1px;
        border-bottom: 1px solid #3c4043;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        @include media(s) {
          width: 4px;
        }
      }
    }
  }
}

/*1日選択コンテンツ*/

.c-select-day_wrap {
  display: flex;
  align-items: center;
  color: #000000;
  dt {
    padding: 0 5.5px 7px;
    text-align: center;
    border-right: 1px solid #ccc;
  }
  dd {
    padding: 10px;
    font-size: 1.3em;
  }
  &.u-conference {
    padding: 4px 5px;
    margin-left: 15px;
    position: absolute;
    left: 0;
    max-width: 85px;
    z-index: 100;
    border-radius: 15px;
    text-align: center;
    top: 40px;
    > li {
      font-size: 1.4em;
    }
    &.is-today {
      background: #fff;
      color: #01a69b;
    }
  }
}

.c-select-day__weekday {
  font-weight: 500;
}

.c-select-day__number {
  font-size: 1.5em;
  font-weight: 500;
}

/* Googleカレンダーコンテンツ */

.g-calendar_box {
  margin-top: 11rem;
}

.c-calendar_wrap {
  display: flex;
  align-items: center;
  dt {
    padding: 0 5.5px 7px;
    text-align: center;
    position: relative;
    width: 56px;
    height: 56px;
  }
  dd {
    border: 1px solid #ccc;
    border-bottom: none;
    position: relative;
    width: calc(100% - 56px);
    height: 56px;
    &::after {
      position: absolute;
      content: "";
      bottom: -1px;
      left: -10px;
      height: 1px;
      width: 10px;
      background-color: #ccc;
    }
  }
  &:last-of-type dd::after {
    width: 0;
  }
}

.c-calendar_time {
  position: absolute;
  bottom: -9px;
  left: 15px;
  font-size: 1.1em;
  color: #808080;
}

.c-calendar_content {
  width: 95%;
  position: absolute;
  height: 100%;
  font-size: 1.1em;
  padding: 10px;
  z-index: 1;
  border-radius: 5px;
  &.is-selected {
    &.u-color01 {
      background-color: #039be5;
      color: #fff;
    }
    &.u-color02 {
      background-color: #0b8043;
      color: #fff;
    }
    &.u-color03 {
      background-color: #d50000;
      color: #fff;
    }
  }
}

.p-select-room .c-select-room__box-wrap {
  // border: 1px solid #01A69B;
  border-right: 1px solid #01a69b;
  border-left: 1px solid #01a69b;
  border-bottom: 1px solid #01a69b;

  border-radius: 20px;
  max-width: 337px;
  width: 95%;
  margin: 0 auto 22px;
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.16);
  position: relative;
  // overflow: hidden;
  &.no-active {
    border: none;
  }
}

.c-select-room__box-ttl {
  width: 100%;
  margin-bottom: 12px;
  background: #01a69b;
  border-radius: 20px;
  padding: 10px 12px;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.42px;
  position: relative;
  #js_select-room_wrap & {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 15px;
    padding: 12px;
  }
  &.is-active {
    border-radius: 19px 19px 0 0;
  }
}

.c-select-room__box-sub__ttl {
  .c-select-room__box-wrap & {
    display: inline-flex;
    align-items: center;
    position: absolute;
    right: 7px;
    top: 0;
    color: #fff;
  }
}

.c-select-room__box {
  margin: 6px 0 0;
  padding-bottom: 2em;
  position: relative;
  .c-modal-content__sub-ttl {
    padding-left: 1.4rem;
  }
}

.u-send {
  .c-select-room__box-wrap {
    border: none;
    padding: 12px 0;
    position: relative;
  }
  .c-select-room__box-ttl {
    line-height: 1.2;
    background-color: inherit;
    padding: 0 10px;
    color: #666;
    font-size: 10px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}

.c-conditions-people__wrap {
  display: flex;
  margin-bottom: 19px;
}

.c-conditions-people__box {
  border: 1px solid #b3b3b3;
  padding: 12px 5px;
  width: calc(50% - 5.5px);
  text-align: center;
  font-size: 11px;
  color: #666666;
  &:first-child {
    margin-right: 15px;
  }
  &:last-child {
  }
  .c-conditions-people {
    border-radius: 0;
  }
}

.c-myreserve-box {
  width: 90%;
  max-width: 335px;
  margin: 0 auto 20px;
  color: #fff;
  border-radius: 20px;
  padding: 18px 20px 20px 24px;
  position: relative;
  &.is-standby {
    background-color: #65a5db;
  }
  &.is-inuse {
    background-color: #ee9093;
  }
}

.verticalAnimate .c-myreserve-box {
  &.verticalSliding,
  &.verticalSliding ~ * {
    animation: cardVerticalSlidingAnimation;
    animation-duration: 0.8s;
  }
}

.c-myreserve__btn-box {
  display: flex;
  justify-content: center;
  .c-myreserve__btn {
    max-width: 116px;
    &:nth-child(2) {
      margin-left: 3em;
      @include media(s) {
        margin-left: 1em;
      }
    }
  }
}

.is-standby .c-myreserve__btn {
  color: #65a5db;
}

.is-inuse .c-myreserve__btn {
  color: #ee9093;
}

.c-myreserve-modal__list-box {
  display: flex;
  margin-bottom: 25px;
}

.c-myreserve-modal__list-wrap {
  color: #666;
}
.c-myreserve-modal__list-wrap.u-check__list {
  margin-right: 2rem;
  width: calc(80% - 2rem);
}
