/* サイドメニュー
========================================================= */

/* サイドメニュー */

.p-calendar__wrap {
  margin-bottom: 20px;
}

/*20200908_時間から検索*/
.c-search-new__box-area {
  margin-bottom: 40px;
}
.c-search-new__box {
  padding-bottom: 22px;
  position: relative;
  &:first-child {
    border-bottom: 1px solid #b3b3b3;
    margin-bottom: 17px;
  }
}
.c-search-new-ttl {
  color: #666666;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 15px;
}
.c-search-new-select__box-wrap {
  display: flex;
  align-items: center;
}
.c-search-new-select__box {
  min-height: 35px;
  border: 1px solid #b3b3b3;
  padding: 4px 7px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    color: #000;
  }
  &.is-error {
    border: 1px solid rgb(193, 72, 72);
  }
}
.c-search-new-date .c-search-new-select__box {
  max-width: 140px;
  width: 100%;
  input {
    width: 100%;
  }
}
.c-search-new-select__date-icon {
  margin-left: 1em;
  max-width: 17px;
}
.c-search-new-time .c-search-new-select__box {
  max-width: 140px;
  width: 100%;
  &::before {
    position: absolute;
    content: "";
    border-bottom-width: initial;
    border-bottom-color: initial;
    width: 8px;
    height: 4px;
    top: calc(50% - 4px);
    right: 10px;
    border-style: solid solid none;
    border-image: initial;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #000000;
    transition: all 0.3s ease-out 0s;
  }
}
.c-search-new-select-while {
  padding: 0 14px;
}
.c-search-new-cancel__btn-wrap {
  margin-left: 23px;
  max-width: 68px;
  position: absolute;
  top: 0;
  right: 0;
}

.c-search-new-cancel__btn {
  background-color: #b3b3b3;
  color: #fff;
  border-radius: 21px;
  text-align: center;
  font-size: 10px;
  padding: 3px 13px;
}
.c-search-new__error {
  position: relative;
  padding: 8px 12px;
  border: 1px solid rgb(193, 72, 72);
  display: block;
  color: rgb(193, 72, 72);
  font-weight: bold;
  font-size: 12px;
  max-width: 320px;
}

.selbox_cc {
  border: none;
}

.selbox_cc_edit {
  border-color: rgb(255, 255, 255);
  &::before {
    position: absolute;
    content: "";
    border-bottom-width: initial;
    border-bottom-color: initial;
    width: 8px;
    height: 4px;
    top: calc(50% - 4px);
    right: 10px;
    border-style: solid solid none;
    border-image: initial;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #ffffff;
    transition: all 0.3s ease-out 0s;
  }
}

.selbox_dd {
  border-color: rgb(0, 0, 0);
}

.selbox_dd_edit {
  border-color: rgb(0, 0, 0);
  &::before {
    position: absolute;
    content: "";
    border-bottom-width: initial;
    border-bottom-color: initial;
    width: 8px;
    height: 4px;
    top: calc(50% - 4px);
    right: 10px;
    border-style: solid solid none;
    border-image: initial;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #000000;
    transition: all 0.3s ease-out 0s;
  }
}
