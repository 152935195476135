/* p-login
----------------------------------------------- */

.p-login {
  position: relative;
  .app-version {
    position: fixed;
    bottom: 5px;
    right: 5px;
  }

  .c-logo__img {
    margin: 2em auto 0;
    max-width: 150px;
    @include media(pcLayout) {
      margin: 50px auto;
      max-width: 175px;
    }
  }
  .c-login-box {
    margin: auto;
    max-width: 600px;
    width: 90%;
    text-align: center;
    margin: 3rem auto;
    @include media(pcLayout) {
      margin: auto;
    }
  }
  .c-login__ttl {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    @include media(pcLayout) {
      font-size: 33px;
    }
  }
  .c-login__error-txt {
    margin-top: 1.5em;
    font-size: 1.25em;
    font-weight: bold;
    color: #ee9093;
    text-align: center;
    display: none;
    &.is-error {
      display: inherit;
    }
    @include media(pcLayout) {
      margin-top: 15px;
      font-size: 21px;
    }
  }
  .c-input-btn {
    padding: 10px 1em 10px 45px;
    border-radius: 40px;
    width: 100%;
    display: inline-block;
    font-size: 15px;
    letter-spacing: 1.5px;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-out;
    cursor: pointer;
    max-width: 500px;
    &.c-login-btn {
      background-color: #01a69b;
      color: #fff;
      max-width: 175px;
      margin: 3rem auto 0;
      padding: 13px 0;
      &:hover {
        transition: all 0.3s ease-out;
        box-shadow: none;
      }
    }
  }
}
