/* 予約モーダル
========================================================= */

.p-modal-ttl__wrap {
  display: flex;
  align-items: center;
  margin: 2rem 1.5rem;

  .c-modal-ttl {
    margin-left: 20px;
    margin-right: 45px;
  }

  .p-modal-btn__wrap {
    display: flex;
    width: 215px;

    .c-reserve__btn {
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

.p-select-room {
  margin-bottom: 2rem;

  .c-select-room__ttl {
    margin-bottom: 8px;
  }
  &.u-send {
    margin-bottom: 0;
  }
}

.p-guest-join__box {
  margin-bottom: 25px;
}

.p-check-box-wrap {
  margin-bottom: 25vh;
}
.c-add-wrap {
  margin: 0 auto 6rem;
  max-width: 170px;
}

.p-detail-box-wrap {
  margin-bottom: 40px;
}
.is-reservation .c-modal-list.c-myreserve-list {
  margin-top: -15px;
}
