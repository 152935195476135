/* _base
========================================================= */
body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  color: #808080;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",
    "Yu Gothic Medium", "游ゴシック Medium", "Yu Gothic", "游ゴシック体",
    "メイリオ", Meiryo, Sans-Serif;
  font-size: 11px;
  line-height: 1;
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  letter-spacing: 0.05em;
}

a {
  text-decoration: none;
}
ul,
ol {
  list-style: none;
}
a {
  transition: all 0.3s ease;
  display: block;
}

p {
  line-height: 1.8;
}
img {
  max-width: 100%;
}
input:focus,
textarea:focus,
button:focus {
  outline: 0;
}
