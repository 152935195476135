.connection-loading {
  display: none;
  background: rgba(255, 255, 255, .5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;

  & > div {
    width: 100%;
    height: 100%;
    background: url('/static/common/loading.gif') no-repeat center;
    background-size: 250px 250px;
  }

  &.show {
    display: initial;
  }
}
