/* ラベル
----------------------------------------------- */

.c-label {
  padding: 5px 0;
  text-align: center;
  &.c-candidate {
    font-size: 10px;
    color: #fff;
    background-color: #b3b3b3;
    width: 37px;
    padding: 2px 4px;
    .is-selected & {
      background-color: #01a69b;
    }
    &.c-select-candidate-ttl {
      font-size: 10px;
      width: 140px;
      text-align: left;
      display: none;
      color: #b3b3b3;
      background-color: transparent;
      &.is-active {
        display: inherit;
      }
    }
    &.c-search-candidate-ttl {
      font-size: 12px;
      font-weight: bold;
      width: 140px;
      text-align: left;
      color: #999999;
      background-color: transparent;
    }
    &.c-myreserve-candidate-ttl {
      font-size: 12px;
      font-weight: bold;
      width: 290px;
      text-align: left;
      color: #fff;
      position: relative;
      height: 1.75em;
      overflow: hidden;
      background-color: transparent;
    }
  }
  &.c-date-label {
    position: absolute;
    top: 10px;
    right: 60px;
    color: #ffffff;
    font-size: 1.3em;
    border: 1px solid #fff;
    border-radius: 13px;
    width: 72px;
    line-height: 1;
    font-weight: bold;
  }
  &.c-select-day__label {
    position: absolute;
    bottom: 6px;
    left: 100px;
    color: #707070;
    font-size: 0.9em;
    background-color: #ffffff;
    border-radius: 13px;
    width: 44px;
    padding: 0;
    margin: -8px auto 10px;
  }
}
.c-myreserve-box.is-reservation .c-myreserve-candidate-ttl {
  width: 185px;
}
.u-conference .c-select-day__label {
  color: #01a69b;
  margin: 0;
  font-size: 0.9em;
  padding: 0px 4px;
  margin: -5px auto;
}

.c-label-tooltips-item {
  position: relative;
  .c-label-tooltips {
    display: none;
    position: absolute;
    font-size: 9px;
    // width: 8em;
    bottom: 1em;
    z-index: 1000;
    padding: 0.75em 0.25em;
    color: #ffffff;
    background: #5d5c5cb3;
    text-align: center;
    white-space: nowrap;
    z-index: 10;
  }
  &.c-select-info__tooltips {
    &.is-active .c-label-tooltips {
      display: block;
      bottom: 1em;
      right: 0;
    }
  }
  &.c-guest__tooltips {
    max-width: calc(100% - 50px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &.is-active .c-label-tooltips {
      display: block;
      bottom: 0;
      right: 5em;
      width: auto;
      padding: 2px;
    }
    &.c-guest__Rset-tooltips {
      width: 100%;
      &.is-active .c-label-tooltips {
        display: block;
        bottom: 0;
        right: 0;
        padding: 2px;
      }
    }
  }
}
.c-myreserve-join__wrap {
  .c-guest__tooltips {
    &.is-active .c-label-tooltips {
      display: block;
      bottom: 0;
      right: 0;
      width: auto;
      padding: 2px;
    }
  }
}
