/* デバイス別表示
----------------------------------------------- */
.pc {
  display: block;

  &-in {
    display: inline;
  }

  &-bl {
    display: block;
  }
  &-inbl {
    display: inline-block;
  }

  &-flex {
    display: flex;
  }
}
