/* ビジュアル
----------------------------------------------- */

.c-visual__fixed {
  position: fixed;
  z-index: 998;
  &.u-side__fixed {
    right: 6vw;
    bottom: 5vh;
  }
}
