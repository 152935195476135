/* 汎用ナビゲーション
========================================================= */

.c-side-menu {
  position: relative;
}

#c-btn-spmenu {
  z-index: 201;
  overflow: hidden;
  width: 18px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  left: 8px;
  top: 0px;
  span {
    display: block;
    width: 18px;
    height: 2px;
    background-color: #fff;
    margin: 0 auto;
    transition: all 0.3s ease-in-out;
    margin-top: 3px;
  }
}

.c-nav__wrap {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 1);
  margin-left: auto;
  padding: 10px 0;
  z-index: 202;
  max-width: 322px;
}

.c-nav-list {
  padding: 0 10px;
  margin-top: 10px;
  > li {
    margin-bottom: 25px;
    padding-left: 1em;
    color: #707070;
  }
  &.c-nav__account-list {
    margin-top: 20px;

    > li {
      // padding-right: 60px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      padding-left: 1em;
      font-weight: 500;
      font-size: 13px;
      color: #9e9e9e;
      &.is-none {
        .c-nav__account-color {
          border: 2px solid #5f6369;
          background-color: #fff;
        }
      }
    }
  }
  &.c-nav__sign-list {
    margin-top: 30px;

    > li {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      padding-left: 1em;
      font-weight: 500;
      font-size: 13px;
      color: #9e9e9e;
    }
  }
}

.c-nav-link {
  text-decoration: none;
  font-weight: 500;
  font-size: 1.4em;
  position: relative;
  display: flex;
  align-items: center;
  color: #707070;
}

.c-nav__img {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  overflow: hidden;
  fill: #5f6369;
}

.c-nav__account-color {
  margin-right: 5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  min-width: 20px;
  &.u-color01 {
    background-color: #3155ce;
  }
}
.c-nav__mail {
  color: #000;
  // font-size: 1.35em;
  font-weight: 500;
  margin-left: 10px;
  line-height: 1.25;
  word-break: break-word;
}
.c-nav__sign-ttl {
  color: #000;
  font-weight: 500;
  margin-left: 10px;
}
