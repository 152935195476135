/* ボタン
----------------------------------------------- */

.c-btn {
  background: #808080;
  border-radius: 1px;
  width: 100%;
  text-align: center;
  display: inline-block;
  padding: 10px 12px;
  color: #fff;
  font-size: 1.4em;
  font-weight: 500;
  letter-spacing: 1px;
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-out;
  cursor: pointer;
  border-radius: 20px;
  &:active {
    opacity: 0.4;
  }
  &.is-active {
    background-color: #01a69b;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  &.c-reserve-btn {
    max-width: 125px;
  }
  &.c-reserve__btn {
    background-color: #d9d9d9;
    cursor: default;
    .c-btn__link {
      filter: drop-shadow(0px 0px 0px #0000001a);
    }
    &.is-active {
      background-color: #01a69b;
      .c-btn__link {
        filter: drop-shadow(0px 3px 1px #0000001a);
        cursor: pointer;
        &:hover {
          filter: drop-shadow(0px 0px 0px #0000001a);
          opacity: 1;
        }
      }
    }
  }
  &.c-add-btn {
    background-color: #b3b3b3;
    border-radius: 17px;
    position: relative;
    font-size: 13px;
    padding: 6px 30px 6px 12px;
    .c-btn__link {
      padding: 0;
    }
    &::before {
      position: absolute;
      content: "";
      width: 13px;
      height: 1px;
      border-top: 1px solid #fff;
      top: 0;
      bottom: 0;
      right: 10px;
      margin: auto;
      transform: rotate(90deg);
    }
    &::after {
      position: absolute;
      content: "";
      width: 13px;
      height: 1px;
      border-top: 1px solid #fff;
      top: 0;
      bottom: 0;
      right: 10px;
      margin: auto;
    }
  }
  &.c-myreserve__btn {
    background-color: #fff;
    border-radius: 20px;
    font-size: 12px;
    font-weight: bold;
    box-shadow: none;
    padding: 10px 6px;
  }
  &.c-myreserve-modal-btn {
    border-radius: 20px;
    font-size: 12px;
    font-weight: bold;
    box-shadow: none;
    color: #fff;
    .is-standby & {
      background-color: #65a5db;
    }
    .is-inuse & {
      background-color: #ee9093;
      // max-width: 117px;
    }
  }
}

.c-select-day__btn {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  fill: #fff;
  top: 0;
  &:active {
    opacity: 0.4;
  }
  &.u-prev {
    right: 30px;
  }
  &.u-next {
    right: 0;
  }
}

// .c-select-day__label.is-today+.c-select-day__btn.u-next {
//     left: 150px;
// }

.c-btn__link {
  display: inline-block;
  width: 100%;
  padding: 8px 23px;
  color: #fff;
  font-size: 15px;
  letter-spacing: 1.5px;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.c-modal-cancel__btn {
  position: relative;
  width: 40px;
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px 0px;
  padding: 5px 0;
  text-align: center;
  background-color: #999;
  border-radius: 15px;
  margin-right: 20px;
  cursor: pointer;
  &:active {
    opacity: 0.4;
  }
  &::before {
    position: absolute;
    content: "";
    width: 11px;
    height: 1px;
    border-top: 2px solid #fff;
    transform: rotate(45deg);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  &::after {
    position: absolute;
    content: "";
    width: 11px;
    height: 1px;
    border-top: 2px solid #fff;
    transform: rotate(-45deg);
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
  }
  .p-select-room & {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    top: 10px;
    margin-right: 0;
  }
  .p-myreserve-modal & {
    margin: 0 10px 10px auto;
    width: 30px;
    height: 30px;
    display: inherit;
  }
}

.c-select-cancel__btn {
  position: relative;
  width: 44px;
  height: 100%;
  background-color: #b3b3b3;
  padding: 5px 0;
  text-align: center;
  cursor: pointer;
  border-radius: 0 15px 15px 0;
  &:active {
    opacity: 0.4;
  }
  &::before {
    position: absolute;
    content: "";
    width: 9px;
    height: 1px;
    border-top: 1px solid #fff;
    transform: rotate(45deg);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  &::after {
    position: absolute;
    content: "";
    width: 9px;
    height: 1px;
    border-top: 1px solid #fff;
    transform: rotate(-45deg);
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
  }
}

.c-guest-cancel__btn {
  position: absolute;
  right: 0;
  width: 20px;
  height: 100%;
  padding: 5px 0;
  text-align: center;
  cursor: pointer;
  &:active {
    opacity: 0.4;
  }
  &::before {
    position: absolute;
    content: "";
    width: 13px;
    height: 1px;
    border-top: 1px solid #999;
    transform: rotate(45deg);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  &::after {
    position: absolute;
    content: "";
    width: 13px;
    height: 1px;
    border-top: 1px solid #999;
    transform: rotate(-45deg);
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
  }
}

.c-input-btn {
  background: #01a69b;
  border-radius: 3px;
  width: 100%;
  text-align: center;
  display: inline-block;
  width: 100%;
  padding: 10px 20px;
  color: #fff;
  font-size: 15px;
  letter-spacing: 1.5px;
  box-shadow: 0px 3px 1px #0000001a;
  transition: all 0.3s ease-out;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 0px #0000001a;
    opacity: 1;
  }
  &:active {
    opacity: 0.4;
  }
  &.c-reserve__btn {
    background-color: #d9d9d9;
    box-shadow: 0px 0px 0px #0000001a;
    cursor: default;
    border-radius: 20px;
    .c-modal-btn__wrap & {
      background-color: rgb(128, 128, 128);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px;
      cursor: default;
      overflow: visible;

      border-radius: 20px;
      &:nth-child(2) {
        margin-left: 1rem;
      }
    }
    &.is-active {
      background-color: #01a69b;
      box-shadow: 0px 3px 1px #0000001a;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 0px 0px #0000001a;
        opacity: 1;
      }
    }
  }
}

.c-modal-btn__wrap {
  display: flex;
  width: 264px;
  margin: 0px auto;
  justify-content: stretch;
}

.c-select__release-btn {
  border-radius: 15px;
  border: 1px solid #01a69b;
  color: #01a69b;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 0;
  width: 100%;
  cursor: pointer;
  &:active {
    opacity: 0.4;
  }
  &.is-active {
    padding: 6px 0;
    background-color: #01a69b;
    color: #fff;
  }
}

.c-side-menu__date-btn {
  width: 75px;
  padding: 1px 0;
  margin-right: 7px;
  margin-left: 10px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  border-radius: 13px;
  border: 1px solid #fff;
  cursor: pointer;
  &:hover {
    color: #01a69b;
    border: 1px solid #01a69b;
    background-color: #fff;
  }
  &:active {
    opacity: 0.4;
  }
}

.c-floor__btn {
  width: 67px;
  padding: 1px 0;
  padding-left: 20px;
  position: absolute;
  right: 10px;
  top: 0;
  color: #01a69b;
  font-weight: bold;
  font-size: 16px;
  border-radius: 15px;
  border: 1px solid #01a69b;
  cursor: pointer;
  letter-spacing: normal;
  padding-left: 20px;
  padding-right: 10px;
  text-align: center;
  &:hover {
    color: #01a69b;
    border: 1px solid #01a69b;
    background-color: #fff;
  }
  &:active {
    opacity: 0.4;
  }
  &::before {
    position: absolute;
    content: "";
    left: 5px;
    width: 10px;
    height: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-image: url($img_path + "modal/floor-icon.svg");
    background-size: contain;
  }
}
