/* ヘッドタイトル
========================================================= */
.c-mv-main__ttl {
  position: absolute;
  top: 60px;
  left: 30px;
  z-index: 5;
  top: 50%;
  transform: translateY(-50%);

  .c-head_h1 {
    font-size: 32px;
    color: #ffffff;
    font-weight: 600;
    line-height: 1.6;
    margin-bottom: 15px;
  }
}

/* ヘッドタイトル
========================================================= */
.c-mv-main__ttl {
  position: absolute;
  top: 60px;
  left: 30px;
  z-index: 5;
  top: 50%;
  transform: translateY(-50%);

  .c-head_h1 {
    font-size: 32px;
    color: #ffffff;
    font-weight: 600;
    line-height: 1.6;
    margin-bottom: 15px;
  }
}
