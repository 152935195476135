// @desc - フォントサイズをpxとremで設定（基本 15px）
// @param $size {int} - 作成したいサイズを設定
// @param $base {int} - 基本的に変更なし
@mixin font($size, $base: 15) {
  font-size: $size + px;
  font-size: ($size / $base) + rem;
}

// @desc - メディアクエリの分岐を作成
// @param $breakPoint {String} - 各ブレイクポイントを設定
// @example
//
// @include mq() { ... }
// @include mq($bp-m) { ... }
@mixin mq($breakPoint: map-get($bp, m)) {
  @media screen and (min-width: $breakPoint) {
    @content;
  }
}

// @desc - レスポンシブクラス作成
@mixin responsive($class) {
  #{$class} {
    @content;
  }
  @include mq(map-get($bp, l)) {
    #{$class}-l {
      @content;
    }
  }
  @include mq(map-get($bp, m)) {
    #{$class}-m {
      @content;
    }
    #{$class}-pc {
      @content;
    }
  }
  @include mq(map-get($bp, s)) {
    #{$class}-s {
      @content;
    }
  }
  @media (max-width: map-get($bp, m) - 1) {
    #{$class}-sp {
      @content;
    }
  }
}

$breakpoints: (
  xs: "only screen and (max-width:320px)",
  s: "only screen and (max-width:360px)",
  m: "only screen and (min-width:376px)",
  spLayout: "only screen and (max-width:816px)",
  pcLayout: "only screen and (min-width:817px)",
  side: "only screen and (min-width:586px)",
);

@mixin media($breakpoint) {
  @media #{map-get($breakpoints , $breakpoint)} {
    @content;
  }
}
